<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col
        cols="12"
        lg="4"
        v-for="(curso, index) in filteredCursos"
        :key="index"
      >
        <v-hover v-slot="{ hover }" v-if="curso.titulo">
          <base-material-card color="#aeaeae" :image="true">
            <v-img
              slot="image"
              lazy-src="@/assets/thumb_default.webp"
              eager
              :aspect-ratio="600 / 397"
              :src="getThumbnail(curso.thumbnail)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-expand-transition>
                <div
                  @click="assistir(curso)"
                  v-if="hover"
                  link
                  class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-3 white--text"
                  style="height: 100%"
                >
                  <v-btn text @click.native="assistir(curso)">
                    <v-icon color="white darken-4" left>
                      mdi-arrow-right-drop-circle
                    </v-icon>
                    Assistir
                  </v-btn>
                </div>
              </v-expand-transition>
            </v-img>

            <h4 class="card-title font-weight-light mt-2 ml-2">
              {{ curso.titulo }}
            </h4>

            <p class="d-inline-flex font-weight-light ml-2 mt-1">
              {{ curso.descricao }}
            </p>

            <template v-slot:actions>
              <!-- <span
                class="display-2 primary--text font-weight-bold"
                v-if="curso.valor"
                >{{ curso.valor | toCurrency
                }}<input
                  v-model="curso.valorPago"
                  v-money="money"
                  type="hidden"
              /></span> -->
              <span
                class="display-2 primary--text font-weight-bold"
                >Disponível</span>
              <v-spacer></v-spacer>
              <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
              <span class="caption grey--text font-weight-normal"
                >{{ curso.duracao || 0 }} horas/aula</span
              >
            </template>
          </base-material-card>
        </v-hover>
      </v-col>
      <v-col cols="12" lg="12" v-if="filteredCursos.length == 0">
        <p class="display-2 pa-5 text-center">
          Você ainda não está matriculado em um curso
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import { db } from "@/db";
import { VMoney } from "v-money";
export default {
  name: "DashboardDashboard",
  firebase() {
    const ref = "/users/" + this.user.uid + "/cursos/";
    const ref2 = "/cursos/" ;
    return {
      cursosUsuario: db.ref(ref),
      cursos: db.ref(ref2),
    };
  },
  data() {
    return {
      cursosUsuario: [],
      cursos: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },

      headers: [],
      items: [],
      tabs: 0,

      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },

  methods: {
    ...mapMutations({
      setCourseTitle: "SET_COURSE_TITLE",
    }),
    complete(index) {
      this.list[index] = !this.list[index];
    },
    getThumbnail(link) {
      return link.replace(
        /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?\?usp=sharing/g,
        "https://drive.google.com/uc?export=download&id=$1"
      );
    },
    assistir(curso) {
      let key = curso[".key"];
      let ultima = curso.ultima ? curso.ultima : "";
      this.setCourseTitle(curso.titulo);
      // this.$router.push("/curso/" + key + "/aula/" + curso.ultima);
      this.$router.push("/curso/" + key + "/aula/" + ultima).catch((err) => {});
    },
    mapCurso(curso) {
      let key = curso[".key"];
      let aulas = curso && curso.aulas ? curso.aulas : [];
      let ultima = curso && curso.ultima ? curso.ultima :null;

      this.cursos.forEach((value)=>{
        if(key === value['.key']){
          curso = value;
        }
      })

      return {
        ...curso,
        aulas: aulas,
        ultima:ultima,
        ".key": key,
      };
    },
    getValues(object) {
      let keys = Object.keys(object);
      let values = Object.values(object);

      keys.forEach(function (key, index) {
        values[index][".key"] = key;
      });
      return values;
    },

  },
  computed: {
    ...mapState(["user"]),
    filteredCursos() {
      let cursos=[];
      cursos = this.cursosUsuario.map(this.mapCurso);
      return cursos;
    },
  },
  mounted() {
    // console.log(this.cursosUsuario);
    // console.log(this.cursos);
  },
  directives: { money: VMoney },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.6;
  position: absolute;
  width: 100%;
}
</style>
